import React from "react";
import { Contract } from "@ethersproject/contracts";
import tokenAbi from "./tokenAbi.json";
import presaleAbi from "./preSaleAbi.json";
import { CHAINRPC, presaleAddress, tokenAddress } from "../environment";
import { ethers } from "ethers";
let walletAddress = "0xcE41406Fbb97e9f5d336EEe50F372fC122B26adF";

const provider = new ethers.providers.JsonRpcProvider(CHAINRPC);

export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function useTokenContract(signer) {
  return useContract(tokenAddress, tokenAbi, signer);
}
export function usePresaleContract(signer) {
  return useContract(presaleAddress, presaleAbi, signer);
}
