import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  InputBase,
  Grid,
  useMediaQuery,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { usePresaleContract } from "../ConnectivityAss/hooks";
import logo from "../images/b.png";
import bnbLogo from "../images/bnblogo.svg";
import Loading from "../loading";
import { toast } from "react-toastify";
import { AppContext } from "../utils";
import { parseUnits } from "@ethersproject/units";
import TimerCountDown from "../Timer";
import { styled } from "@mui/styles";
import Web3 from "web3";
import { maxBNBAmount, minBNBAmount, nextRoundFactor } from "../environment";

const renderNumber = (num, def) => {
  if (isNaN(parseInt(num))) return def || "...";

  return parseInt(num).toLocaleString();
};

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  width: "100%",
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#d1d1d1",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#2a347b",
  },
}));

function Presale() {
  const { account, signer, web3Provider } = useContext(AppContext);
  const preSaleContract = usePresaleContract(signer);
  const matches = useMediaQuery("(max-width:700px)");
  const [amount, setamount] = useState("");
  const [tokens, settokens] = useState("0");
  const [maxTokens, setMaxTokens] = useState("");
  const [loading, setloading] = useState(false);
  const [hardCap, sethardCap] = useState(4000);
  const [sold, setsold] = useState(0);
  const [progress, setprogress] = useState(0);
  const [tokensPerBNB, setTokensPerBNB] = useState(null);
  const [GFEBalance, setGFEBalance] = useState(null);

  const init = async () => {
    try {
      let tokenReceive = tokensPerBNB * +amount;
      settokens(tokenReceive);
      // console.log(tokenReceive, "tokenReceive");
    } catch (error) {}
  };
  useEffect(() => {
    if (amount) {
      init();
    }
  }, [amount]);
  // useEffect(() => {
  //   const initSoftCap = async () => {
  //     try {
  //       const softCap = await preSaleContract.amountRaised();
  //       let raised = +formatUnits(softCap.toString());
  //       let prog = (+raised / +hardCap) * 100;
  //       setsold(raised);
  //       setprogress(+prog);
  //     } catch (error) {}
  //   };
  //   initSoftCap();
  // }, []);
  const buyHandler = async () => {
    if (account) {
      if (!amount) {
        toast.error("Error! Please enter a amount");
      } else if (isNaN(amount) || +amount < 0) {
        toast.error("Error! Please enter a valid amount");
      } else if (+amount == 0) {
        toast.error("Error!  Please enter a valid amount");
      } else if (+amount > 0 && +amount < minBNBAmount) {
        toast.error(`Error! Minimum amount is ${minBNBAmount} BNB`);
      } else if (+amount !== 0 && +amount > maxBNBAmount) {
        toast.error(`Error! Maximum amount is ${maxBNBAmount} BNB`);
      } else {
        try {
          setloading(true);
          console.log(
            parseUnits(amount.toString()).toString(),
            "parseUnits(amount.toString())"
          );
          const tx = await preSaleContract.deposit({
            value: parseUnits(amount.toString()).toString(),
          });
          await tx.wait();
          toast.success("Success! Transaction confirmed.");
          setloading(false);
          window.location.reload();
        } catch (error) {
          setloading(false);
          if (error?.data?.message) {
            toast.error(error?.data?.message);
          } else {
            toast.error(error?.message);
          }
        }
      }
    } else {
      toast.error("Error! Please connect your wallet.");
    }
  };

  useEffect(() => {
    async function getBal() {
      if (web3Provider) {
        const x = await web3Provider.getBalance(account);
        const y = Web3.utils.fromWei(x.toString(), "ether");
        console.log(x, y);
        setMaxTokens(y);
      }
    }
    getBal();
  }, [web3Provider, account]);

  useEffect(() => {
    async function getBal() {
      try {
        const rate = await preSaleContract.rate();
        setTokensPerBNB(rate.toString());
      } catch (e) {
        console.log(e);
      }
    }
    getBal();
  }, [preSaleContract]);

  useEffect(() => {
    async function getBal() {
      try {
        const toks = await preSaleContract.purchasedTokens(account);
        console.log(toks);
        if (toks && toks.toString() !== "0") {
          setGFEBalance(Web3.utils.fromWei(toks.toString(), "ether"));
        }
      } catch (e) {
        console.log(e);
      }
    }
    getBal();
  }, [account, preSaleContract]);

  return (
    <Box pt={5} pb={10} position="relative" zIndex={1}>
      <Loading loading={loading} />

      <Container maxWidth="md">
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid mb={5} container spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                width="100%"
                height="100%"
                boxShadow="#2a347b 0px 0px 20px 3px"
                style={{
                  background: "rgb(255 255 255)",
                }}
                borderRadius="0px"
                borderTop="20px solid #"
                borderBottom="20px solid #"
                borderLeft="10px solid #"
                borderRight="10px solid #"
                display="flex"
                flexDirection="column"
                p={2}
              >
                <Box
                  fontWeight="900"
                  fontSize="25px"
                  color="#e50682"
                  fontFamily="Raleway"
                  textAlign="center"
                >
                  BUY BDKMV
                </Box>

                <Box
                  mt={2}
                  mb={2}
                  style={{
                    boxShadow: "#2a347b 0px 0px 8px 1px",
                    background: "transparent",
                    paddingLeft: 15,
                    borderRadius: "0px",
                  }}
                  pb={2}
                  pt={1}
                >
                  <Box
                    style={{
                      color: "#2a347b",
                      fontFamily: "Raleway",
                      fontWeight: "500",
                      fontSize: "18px",
                      marginTop: "10px",
                      width: "100%",
                      paddingRight: "15px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    From:{" "}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-beteen"
                    alignItems="center"
                  >
                    <InputBase
                      style={{
                        color: "#000000",
                        fontFamily: "Raleway",
                        fontWeight: "600",
                        fontSize: "18px",
                        marginTop: "10px",
                        width: "60%",
                        paddingRight: "15px",
                      }}
                      fullWidth
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      placeholder="Enter BNB"
                      value={amount}
                      onChange={(e) => {
                        setamount(e.target.value);
                      }}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      borderRadius="0px"
                      height={matches ? "35px" : "auto"}
                      // pl={matches ? 1 : 3}
                      // pr={matches ? 2 : 4}
                      // py={1}
                      mr={2}
                      style={{
                        color: "#2a347b",
                        fontFamily: "Raleway",
                        fontWeight: "900",
                        fontSize: "16px",
                        marginTop: "10px",
                        background: "transparent",
                        width: "40%",
                        // boxShadow: "#2a347b 0px 0px 8px 1px",
                        // borderBottom: "5px solid #",
                      }}
                    >
                      <div style={{ textAlign: "right", width: "100%" }}>
                        <img width="50px" height="50px" src={bnbLogo} alt="" />
                        <span style={{ marginLeft: -10 }}>BNB</span>
                      </div>
                    </Box>
                  </Box>
                  {maxTokens !== "" && (
                    <Box
                      style={{
                        color: "#2a347b",
                        fontSize: "18px",
                        marginTop: 5,
                      }}
                    >
                      Balance: {parseFloat(maxTokens).toLocaleString()} BNB
                    </Box>
                  )}
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    boxShadow: "#2a347b 0px 0px 8px 1px",
                    backgroundColor: "transparent",
                    borderRadius: "0px",
                    marginTop: 10,
                  }}
                  py={2}
                  px={2}
                >
                  <Box
                    mb={1}
                    style={{
                      color: "#2a347b",
                      fontFamily: "Raleway",
                      fontWeight: "500",
                      fontSize: "18px",
                      width: "100%",
                      paddingRight: "15px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    To:{" "}
                  </Box>

                  <Box
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontFamily: "Raleway",
                        fontWeight: "500",
                        color: "#2a347b",
                        width: "60%",
                      }}
                      className="heading"
                    >
                      {isNaN(tokens) ? "--" : parseInt(tokens).toLocaleString()}
                    </span>
                    <Box
                      display="flex"
                      alignItems="right"
                      borderRadius="0px"
                      // height={matches ? "35px" : "auto"}
                      // pl={matches ? 1 : 3}
                      // pr={matches ? 2 : 4}
                      // py={1}
                      style={{
                        color: "#2a347b",
                        width: "40%",
                        // fontFamily: "Raleway",
                        // fontWeight: "500",
                        // fontSize: "16px",
                        // marginTop: "10px",
                        // boxShadow: "#2a347b 0px 0px 8px 1px",
                        // background: "transparent",
                        // borderBottom: "5px solid #",
                      }}
                    >
                      <div style={{ textAlign: "right", width: "100%" }}>
                        <img width="50px" height="50px" src={logo} alt="" />
                      </div>
                    </Box>
                  </Box>

                  {GFEBalance && GFEBalance !== "" && (
                    <Box
                      style={{
                        color: "#2a347b",
                        fontSize: "18px",
                        marginTop: 5,
                      }}
                    >
                      Balance: {parseFloat(GFEBalance || 0).toLocaleString()}{" "}
                      BDKMV
                    </Box>
                  )}
                </Box>

                <Box mt={5} display="flex" flexDirection="column">
                  <Box textAlign="center" width="100%">
                    <Button
                      style={{
                        background: "#e50682",
                        // boxShadow: "#2a347b 0px 0px 8px 1px",
                        fontSize: "18px",
                        borderRadius: "0px",
                        width: "176px",
                        height: "45px",
                        color: "#ffffff",
                        textTransform: "capitalize",
                        borderBottom: "5px solid #",
                        fontFamily: "Raleway",
                        fontWeight: "900",
                      }}
                      onClick={() => buyHandler()}
                    >
                      BUY
                    </Button><br></br>
		    <br></br>
		    <br></br>
		    <a href="https://claim.cryptoeducation.pro" Target="_blank" className="Button">
		    <h2>Go To Claim &rarr;</h2>
	            <h2>BDKMV Tokens Cannot Be Claimed Before Presale End Date !!</h2>
		</a>
		 </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box
                width="100%"
                height="100%"
                boxShadow="#2a347b 0px 0px 20px 3px"
                style={{
                  background: "rgb(255, 255, 255)",
                }}
                borderRadius="0px"
                display="flex"
                flexDirection="column"
                borderTop="20px solid #"
                borderBottom="20px solid #"
                borderLeft="10px solid #"
                borderRight="10px solid #"
                p={2}
              >
                {/* <BorderLinearProgress
                  style={{ height: 20, marginTop: 10 }}
                  variant="determinate"
                  value={progress}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    fontFamily="Raleway"
                    fontSize="15px"
                    fontWeight={700}
                    color="#2a347b"
                  >
                    {parseFloat(+sold).toFixed(4)} BNB
                  </Box>
                  <Box
                    fontFamily="Raleway"
                    fontSize="15px"
                    fontWeight={700}
                    color="#2a347b"
                  >
                    {" "}
                    {hardCap} BNB
                  </Box>
                </Box> */}
                <Box
                  fontWeight="900"
                  fontSize="25px"
                  color="#e50682"
                  fontFamily="Raleway"
                  textAlign="center"
                >
                  TOKEN INFO
                </Box>
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Token Name
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    BDKMV
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Token Symbol
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    BDKMV
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Token Decimals
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    18
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Min Deposit
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    {minBNBAmount} BNB
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Max Deposit
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    {maxBNBAmount} BNB
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Tokens Per BNB
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    {renderNumber(tokensPerBNB, "Loading...")}
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Round End
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Feb 2 2024
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Next Round Price
                  </Box>
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    {renderNumber(parseInt(tokensPerBNB) * nextRoundFactor, "...")} Per
                    BNB
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom="1px solid #2a347b"
                  pb={2}
                  mt={2}
                >
                  <Box
                    fontWeight="600"
                    borderRadius="8px"
                    fontSize="16px"
                    color="#2a347b"
                    fontFamily="Raleway"
                  >
                    Token Contract
                  </Box>
                  <a
                    href="https://bscscan.com/address/0x7E8d27731C9DD061C225639cF063Ad314eA15C69#code"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      fontWeight="600"
                      borderRadius="8px"
                      fontSize="16px"
                      color="#2a347b"
                      fontFamily="Raleway"
                    >
                      0x7E8...15C69
                    </Box>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            width="100%"
            borderRadius={10}
            backgroundColor="#E50682"
            paddingTop={2}
            paddingBottom={4}
          >
            <Box
              fontWeight="900"
              fontSize="52px"
              color="#ffffff"
              fontFamily="Segoe UI Black"
              textAlign="center"
              text-shadow="6px 1 6px black"
            >
              PRESALE ENDS IN
            </Box>
            <TimerCountDown />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Presale;
