import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Box, useMediaQuery } from "@mui/material";
import { presaleFinishString } from "./environment";

export default function TimerCountDown() {
  const matches = useMediaQuery("(max-width:700px)");

  let interval = useRef();
  // console.log("time", time);

  const [countTime, setCountDateTime] = useState({
    time_days: 0,
    time_Hours: 0,
    time_Minusts: 0,
    time_seconds: 0,
  });

  const startTime = async () => {
    let countDownDate = moment.utc(presaleFinishString).format("x");
    let interval = setInterval(() => {
      let now = moment.utc().format("x");
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minuts = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setCountDateTime({
          ...countTime,
          time_days: days,
          time_Hours: hours,
          time_Minusts: minuts,
          time_seconds: seconds,
        });
      }
    }, 1000);
  };
  useEffect(() => {
    startTime();
  }, []);
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          mr={matches ? 1 : 5}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            fontSize={matches ? "60px" : "90px"}
            fontWeight="900"
            textAlign="center"
            fontFamily="Raleway"
            bgcolor="#"
          >
            {countTime.time_days}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#2A347B"
            fontSize={matches ? "15px" : "19px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Raleway"
            mt={-2}
          >
            Days
          </Box>
        </Box>
        <Box
          mr={matches ? 1 : 5}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            fontSize={matches ? "60px" : "90px"}
            fontWeight="900"
            textAlign="center"
            fontFamily="Raleway"
            bgcolor="#"
          >
            {countTime.time_Hours}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#2A347B"
            fontSize={matches ? "15px" : "19px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Raleway"
            mt={-2}
          >
            Hours
          </Box>
        </Box>
        <Box
          mr={matches ? 1 : 5}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            borderRadius="0px"
            width={matches ? "70px" : "110px"}
            fontSize={matches ? "60px" : "90px"}
            fontWeight="900"
            textAlign="center"
            fontFamily="Raleway"
            bgcolor="#"
          >
            {countTime.time_Minusts}
          </Box>
          <Box
            width={matches ? "70px" : "110px"}
            color="#2A347B"
            fontSize={matches ? "15px" : "19px"}
            fontWeight="500"
            textAlign="center"
            fontFamily="Raleway"
            mt={-2}
          >
            Minutes
          </Box>
        </Box>
        {!matches && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box
              borderRadius="0px"
              width={matches ? "70px" : "110px"}
              fontSize={matches ? "60px" : "90px"}
              fontWeight="900"
              textAlign="center"
              fontFamily="Raleway"
              bgcolor="#"
            >
              {countTime.time_seconds}
            </Box>
            <Box
              width={matches ? "70px" : "110px"}
              color="#2A347B"
              fontSize={matches ? "15px" : "19px"}
              fontWeight="500"
              textAlign="center"
              fontFamily="Raleway"
              mt={-2}
            >
              Seconds
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
