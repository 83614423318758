export const tokenAddress = "0x7E8d27731C9DD061C225639cF063Ad314eA15C69";
export const presaleAddress = "0x7e35C61f761B0e87AC790CA89ca546603F0F8372";
export const minBNBAmount = 0.05;
export const maxBNBAmount = 20;
export const nextRoundFactor = 0.85;
export const changeRoundWarningDelta = 0.75;
export const presaleFinishString = "feb 2 2024, 12:01:00";

export const MAINNET_CHAINID = 56;
export const TESTNET_CHAINID = 97;

export const MAINNET_RPC = "https://bsc-dataseed1.binance.org";
export const TESTNET_RPC = "https://data-seed-prebsc-2-s3.binance.org:8545";

export const CHAINID = MAINNET_CHAINID;
export const CHAINRPC = MAINNET_RPC;
