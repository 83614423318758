import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Presale from "./components/Presale";
import NetworkChange from "./networkSwitch";
import Web3 from "web3";
import { CHAINID, CHAINRPC } from "./environment";

const web3 = new Web3(Web3.givenProvider ? Web3.givenProvider : CHAINRPC);

function App() {
  const [openSwitch, setopenSwitch] = useState(false);

  useEffect(() => {
    let chain = async () => {
      const chainid = await web3.eth.getChainId();
      if (chainid !== CHAINID) {
        setopenSwitch(true);
      }
    };
    chain();
  }, []);
  return (
    <>
      <NetworkChange open={openSwitch} setOpen={setopenSwitch} />
      <Header />
      <Presale />
    </>
  );
}

export default App;
